import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Generic } from "rbx"

import Header from "src/components/header"
import Footer from "src/components/footer"

import "rbx/index.css"
import "../styles/main.scss"

const Layout = props => {
  const data = useStaticQuery(graphql`
    query SiteTitleLandingQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  const { title, description } = data.site.siteMetadata

  return (
    <Generic id="landing">
      <Header />
      {props.children}
      <Footer siteTitle={title} siteDescription={description} />
    </Generic>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
}

export default Layout
