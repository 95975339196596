import React, { useEffect, useState } from "react"
import { Column, Container, Section, Title } from "rbx"

import ArticlesSectionItem from "./articlesItem"

const axios = require("axios")

const ArticlesSection = ({ apiEndpoint, articlesImgUrlPrefix }) => {
  const [articles, setArticles] = useState([])
  useEffect(() => {
    axios
      .get(`${apiEndpoint}?limit=8&is_pinned=true`)
      .then(response => {
        setArticles(response.data)
      })
      .catch(e => {})
  }, [apiEndpoint])

  if (articles.length < 4) return ""

  const createMatrix = (items, size) => {
    let matrix = []

    for (let x = 0; x < size.y; x++) {
      let row = []
      for (let y = 0; y < size.x; y++) {
        let index = x * size.x + y
        let content = items[index] ? (
          <ArticlesSectionItem
            key={"article" + index}
            item={items[index]}
            articlesImgUrlPrefix={articlesImgUrlPrefix}
          />
        ) : (
          ""
        )
        row.push(<Column>{content}</Column>)
      }
      matrix.push(<Column.Group gapSize={8}>{row}</Column.Group>)
    }
    return <>{matrix}</>
  }

  return (
    <Section id="articles-section">
      <Container className="has-text-centered">
        <Title size="2">#ГолосПамяти в СМИ</Title>
        {createMatrix(articles, { x: 4, y: 1 })}
      </Container>
    </Section>
  )
}

export default ArticlesSection
