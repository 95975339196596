import React from "react"
import { Column, Container, Hero } from "rbx"

const MainSection = () => {
  return (
    <Hero id="desc-section" size="fullheight-with-navbar">
      <Container style={{ width: "100%" }}>
        <Column.Group style={{ height: "100%" }} centered={true} vcentered>
          <Column style={{ height: "100%", textAlign: "center" }}>
            <img
              src="/desc-logo.svg"
              style={{
                padding: "3rem 3rem 3rem 1rem",
                height: "100%",
                textAlign: "center",
              }}
              alt="Голос Памяти"
            />
          </Column>
        </Column.Group>
      </Container>
    </Hero>
  )
}

export default MainSection
