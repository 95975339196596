import React from "react"
import { Column, Container, Content, Generic, Icon, Section, Title } from "rbx"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faUsers,
  faVideo,
  faClipboardList,
} from "@fortawesome/free-solid-svg-icons"

import { ParticipateModalButton } from "src/components/modal"

const StepsSection = () => (
  <Section id="steps-section">
    <Container>
      <Title size="2" className="has-text-centered">
        Как принять участие в акции
      </Title>
      <Content className="is-medium">
        <Column.Group gapSize={8}>
          <Column>
            <Generic className="has-text-centered">
              <Icon>
                <FontAwesomeIcon icon={faVideo} size="4x" />
              </Icon>
            </Generic>
            <Title subtitle size={4} className="has-text-centered">
              Сделайте аудио или видеозапись собственного прочтения
              стихотворения о войне
            </Title>
            <p>
              Наша <Link to="/creations/">подборка стихов</Link> в помощь
              <br />
              <Link to="/tips/">Советы</Link> по созданию качественного видео
            </p>
          </Column>
          <Column>
            <Generic className="has-text-centered">
              <Icon>
                <FontAwesomeIcon icon={faUsers} size="4x" />
              </Icon>
            </Generic>
            <Title subtitle size={4} className="has-text-centered">
              Опубликуйте запись
              <br />в социальной сети
            </Title>
            <p>
              <span className="nowrap">Не забудьте</span> проставить хештеги
              #ГолосПамяти, #ЧитаемСтихиОвойне{" "}
              <span className="nowrap">и открыть</span> доступ{" "}
              <span className="nowrap">к публикации</span>
            </p>
          </Column>
          <Column>
            <Generic className="has-text-centered">
              <Icon>
                <FontAwesomeIcon icon={faClipboardList} size="4x" />
              </Icon>
            </Generic>
            <Title subtitle size={4} className="has-text-centered">
              Пришлите нам ссылку
              <br />
              на вашу публикацию
            </Title>
            <p>
              Ваши имя и ссылка на стихотворение появятся
              <br /> в <Link to="/works/">Списке участников</Link>
            </p>
          </Column>
        </Column.Group>
        <Generic className="has-text-centered">
          <br />
          <br />
          <ParticipateModalButton color="danger" size="large" rounded={true}>
            Прислать свой #ГолосПамяти
          </ParticipateModalButton>
        </Generic>
      </Content>
    </Container>
  </Section>
)

export default StepsSection
