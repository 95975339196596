import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "src/layouts/landing"
import SEO from "src/components/seo"

import MainSection from "src/components/landing/main"
import AboutSection from "src/components/landing/about"
import CreationsSection from "src/components/landing/creation"
import StatSection from "src/components/landing/stats"
import StepsSection from "src/components/landing/steps"
import PurposeSection from "src/components/landing/purpose"
import WorksSection from "src/components/landing/worksMore"
import PartnersSection from "src/components/landing/partners"
import ArticlesSection from "src/components/landing/articles"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          articlesApiEndpointUrl
          partnersApiEndpointUrl
          statsApiEndpointUrl
          worksApiEndpointUrl
          partnersLogoUrlPrefix
          articlesImgUrlPrefix
          worksInRow
        }
      }
    }
  `)

  const {
    articlesApiEndpointUrl,
    partnersApiEndpointUrl,
    statsApiEndpointUrl,
    worksApiEndpointUrl,
    partnersLogoUrlPrefix,
    articlesImgUrlPrefix,
    worksInRow,
  } = data.site.siteMetadata

  return (
    <Layout>
      <SEO />
      <MainSection />
      <CreationsSection />
      <AboutSection />
      <WorksSection apiEndpoint={worksApiEndpointUrl} worksInRow={worksInRow} />
      <StepsSection />
      <StatSection apiEndpoint={statsApiEndpointUrl} />
      <PurposeSection />
      <PartnersSection
        apiEndpoint={partnersApiEndpointUrl}
        partnersLogoUrlPrefix={partnersLogoUrlPrefix}
      />
      <ArticlesSection
        apiEndpoint={articlesApiEndpointUrl}
        articlesImgUrlPrefix={articlesImgUrlPrefix}
      />
    </Layout>
  )
}

export default IndexPage
