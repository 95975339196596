import React from "react"
import { Column, Container, Content, Section } from "rbx"

const CreationsSection = () => (
  <Section id="creation-section">
    <Container>
      <Column.Group>
        <Column size={4} offset={4}>
          <Content className="is-large">
            <p>
              <span className="hpquote">«</span>Я не напрасно беспокоюсь,
              <br />
              Чтоб не забылась та война:
              <br />
              Ведь эта память — наша совесть.
              <br />
              Она, как сила, нам нужна».
            </p>
          </Content>
          <Content className="is-medium">
            <p>
              Юрий Воронов (1929—1993),
              <br />
              советский поэт, журналист, общественный деятель
            </p>
          </Content>
        </Column>
      </Column.Group>
    </Container>
  </Section>
)

export default CreationsSection
