import React from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"

const PartnersSectionItem = ({ partner, partnersLogoUrlPrefix }) => {
  const { site_url, logo_url, name } = partner
  const url = `${partnersLogoUrlPrefix}/${logo_url}`

  return (
    <a href={site_url} target="_blank" rel="noopener noreferrer">
      <LazyLoadImage className="logo" alt={name} src={url} />
    </a>
  )
}

export default PartnersSectionItem
