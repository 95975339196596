import React, { useEffect, useState } from "react"
import { Column, Container, Content, Section, Title } from "rbx"

import PartnersSectionItem from "./partnersItem"

const axios = require("axios")

const PartnersSection = ({ apiEndpoint, partnersLogoUrlPrefix }) => {
  const [partners, setPartners] = useState([])
  useEffect(() => {
    axios
      .get(`${apiEndpoint}?limit=6&is_pinned=true`)
      .then(response => {
        setPartners(response.data)
      })
      .catch(e => {})
  }, [apiEndpoint])

  if (partners.length > 0) {
    return (
      <Section id="partners-section" className="has-text-centered">
        <Container>
          <Title size="2">Партнёры проекта</Title>
          {partners.map((item, index) => {
            return (
              <PartnersSectionItem
                key={"partner" + index}
                partner={item}
                partnersLogoUrlPrefix={partnersLogoUrlPrefix}
              />
            )
          })}
          <Column.Group>
            <Column size={8} offset={2}>
              <Content size="medium">
                <p>
                  Мы приглашаем поддержать инициативу #ГолосПамяти своих коллег
                  из федеральных и региональных СМИ, а также общественные
                  организации, государственные и частные компании.
                </p>
                <p>
                  Если вы также, как и мы, готовы разместить на своем
                  корпоративном сайте или в соцсетях видеоролики своих
                  сотрудников, читающих стихи, напишите нам на{" "}
                  <a href="mailto:info@golospamyati.ru">info@golospamyati.ru</a>{" "}
                  — мы вышлем логотип проекта, а логотип вашей компании появится
                  на этой странице.
                </p>
              </Content>
            </Column>
          </Column.Group>
        </Container>
      </Section>
    )
  } else {
    return ""
  }
}

export default PartnersSection
