import React, { useEffect, useState } from "react"
import { Button, Column, Container, Section, Title } from "rbx"

import WorksSectionItem from "./worksItem"

const axios = require("axios")

const WorksSection = ({ apiEndpoint, worksInRow }) => {
  const [works, setWorks] = useState([])
  const [worksToShow, setWorksToShow] = useState(8)

  useEffect(() => {
    axios
      .get(`${apiEndpoint}?is_pinned=true`)
      .then(response => {
        const accepted_works = response.data.items.filter(work =>
          ["YOUTUBE", "FACEBOOK"].includes(work.url_source)
        )
        setWorks(accepted_works)
      })
      .catch(e => {})
  }, [apiEndpoint])

  if (works.length === 0) return ""

  const size = {
    x: worksInRow,
    y: (worksToShow - (worksToShow % worksInRow)) / worksInRow,
  }

  const createMatrix = (items, size) => {
    let matrix = []

    for (let x = 0; x < size.y; x++) {
      let row = []
      for (let y = 0; y < size.x; y++) {
        let index = x * size.x + y
        let content = items[index] ? (
          <WorksSectionItem key={"work" + index} item={items[index]} />
        ) : (
          ""
        )
        row.push(
          <Column
            mobile={{ size: "full" }}
            tablet={{ size: "half" }}
            desktop={{ size: "half" }}
            widescreen={{ size: "half" }}
            fullhd={{ size: "one-quarter" }}
          >
            {content}
          </Column>
        )
      }
      matrix.push(<Column.Group gap={4}>{row}</Column.Group>)
    }
    return <>{matrix}</>
  }

  return (
    <Section id="works-section">
      <Container className="has-text-centered">
        <Title size="2">Наш #ГолосПамяти</Title>
        {createMatrix(works.slice(0, worksToShow), size)}
        {works.length > worksToShow && (
          <Button
            rounded
            color="danger"
            size="medium"
            onClick={() => {
              setWorksToShow(worksToShow + 8)
            }}
            style={{ marginTop: "3rem" }}
          >
            Показать ещё
          </Button>
        )}
      </Container>
    </Section>
  )
}

export default WorksSection
