import React from "react"
import { Column, Container, Content, Section, Title } from "rbx"

import { ParticipateModalButton } from "src/components/modal"

const PurposeSection = () => (
  <Section id="purpose-section">
    <Container>
      <Column.Group>
        <Column size={10} offset={1}>
          <Content className="has-text-centered">
            <Title
              size={2}
              style={{
                color: "white",
                fontWeight: "300",
                letterSpacing: ".1rem",
              }}
            >
              Многоликий, наш общий #ГолосПамяти позволит эмоционально
              объединить <span className="nowrap">в виртуальном</span>{" "}
              пространстве аудиторию разных возрастов, профессий,
              национальностей, вероисповеданий всей России,{" "}
              <span className="nowrap">а также</span> ближнего{" "}
              <span className="nowrap">и дальнего</span> зарубежья.
            </Title>
          </Content>
        </Column>
      </Column.Group>
      <Column.Group>
        <Column size={10} offset={1}>
          <Content className="is-medium has-text-justified">
            <Title
              size={4}
              style={{
                color: "white",
                fontWeight: "400",
                letterSpacing: ".1rem",
                lineHeight: "140%",
              }}
            >
              Память <span className="nowrap">о тех</span>, кто одержал Победу{" "}
              <span className="nowrap">во Второй</span> мировой войне, отстоял{" "}
              <span className="nowrap">для нас</span> возможность мирно
              трудиться, живет <span className="nowrap">в наших</span> сердцах.
              Мы, дети <span className="nowrap">и внуки</span> детей, родившихся
              после войны, <span className="nowrap">в собственных</span> сердцах
              должны одерживать эту Победу вновь{" "}
              <span className="nowrap">и вновь</span>.
            </Title>
          </Content>
        </Column>
      </Column.Group>
      <Column.Group>
        <Column size={10} offset={1}>
          <Content className="has-text-centered">
            <Title
              size={4}
              style={{
                color: "white",
                fontWeight: "400",
                letterSpacing: ".1rem",
                lineHeight: "140%",
              }}
            >
              Читаем стихи и формируем список участников акции
              <br />
              <span className="nowrap">до дня</span> проведения проведения
              праздничного парада{" "}
              <span className="nowrap">на Красной площади</span>{" "}
              <span className="nowrap">в Москве</span>{" "}
              <span className="nowrap">в честь 75-летия Победы</span>.
            </Title>
            <Title
              size={4}
              style={{
                color: "white",
                fontWeight: "bold",
                letterSpacing: ".1rem",
                lineHeight: "140%",
              }}
            >
              Присоединяйте свой Голос Памяти и Совести!
            </Title>
          </Content>
        </Column>
      </Column.Group>
      <Column.Group>
        <Column size={6} offset={3}>
          <Content className="is-large has-text-centered">
            <ParticipateModalButton color="danger" size="large" rounded={true}>
              Стать участником
            </ParticipateModalButton>
          </Content>
        </Column>
      </Column.Group>
    </Container>
  </Section>
)

export default PurposeSection
