import React, { useEffect, useState } from "react"
import { Heading, Generic, Level, Section, Title } from "rbx"
import { Link } from "gatsby"

const axios = require("axios")

const StatSection = ({ apiEndpoint }) => {
  const [stats, setStats] = useState([])
  useEffect(() => {
    axios
      .get(`${apiEndpoint}`)
      .then(response => {
        setStats(response.data)
      })
      .catch(e => {})
  }, [apiEndpoint])

  if (!stats || stats.works_count < 100) return ""

  return (
    <Section id="stat-section" textAlign="centered">
      <Title size="2">#ГолосПамяти в цифрах</Title>
      <Level>
        <Level.Item>
          <Generic>
            <Heading>Работ</Heading>
            <Title as="p">{stats.works_count}</Title>
          </Generic>
        </Level.Item>
        <Level.Item>
          <Generic>
            <Heading>Участников</Heading>
            <Title as="p">{stats.participants_count}</Title>
          </Generic>
        </Level.Item>
        <Level.Item>
          <Generic>
            <Heading>Партнёров</Heading>
            <Title as="p">{stats.partners_count}</Title>
          </Generic>
        </Level.Item>
        <Level.Item>
          <Generic>
            <Heading>Стихотворений</Heading>
            <Title as="p">{stats.creations_count}</Title>
          </Generic>
        </Level.Item>
      </Level>
      <Title size={4}>
        <Link to="/works/">Список участников #ГолосПамяти</Link>
      </Title>
    </Section>
  )
}

export default StatSection
