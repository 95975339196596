import React from "react"
import { Column, Container, Content, Section, Title } from "rbx"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-gtag"
import { LazyLoadImage } from "react-lazy-load-image-component"

const AboutSection = () => (
  <Section id="about-section">
    <Container>
      <Column.Group>
        <Column size={7}>
          <LazyLoadImage
            src="/landing/collage.jpg"
            style={{ width: "80%", margin: "-7rem 0 0 3rem" }}
            alt=""
          />
        </Column>
        <Column size={5}>
          <Title size="2">Об инициативе</Title>
          <Content className="is-large">
            <p>
              Мы: редакция делового издания{" "}
              <span className="nowrap">
                «
                <OutboundLink href="https://www.if24.ru/golospamyati/">
                  Инвест-Форсайт
                </OutboundLink>
                »
              </span>
              , наши коллеги, партнеры, друзья, родственники и{"\u00A0"}знакомые
              — начали читать стихи о{"\u00A0"}войне
              каждый день юбилейного года{" "}
              <span className="nowrap">75-летия Победы</span>{" "}
              в{"\u00A0"}Великой Отечественной войне.
            </p>
            <p>
              К{"\u00A0"}нашему марафону присоединились люди из{"\u00A0"}разных
              уголков страны и{"\u00A0"}зарубежья; и{"\u00A0"}все продолжают
              читать стихи&hellip;
            </p>
            <p>
              <Link to="#steps-section">Присоединиться к #ГолосПамяти</Link>
            </p>
          </Content>
        </Column>
      </Column.Group>
    </Container>
  </Section>
)

export default AboutSection
