import React from "react"
import { Card, Title } from "rbx"

const moment = require("moment")
moment.locale("ru")

const ArticlesSectionItem = ({ item, articlesImgUrlPrefix }) => {
  return (
    <Card style={{ height: "100%" }}>
      <Card.Image>
        <a href={item.url} target="_blank" rel="noopener noreferrer">
          <img
            src={`${articlesImgUrlPrefix}/${item.img_url}`}
            alt={item.title}
          />
        </a>
      </Card.Image>
      <Card.Content>
        <Title size="5">
          <a href={item.url} target="_blank" rel="noopener noreferrer">
            {item.title}
          </a>
          <span className="has-text-grey">
            {" / "}
            {moment(item.created).format("DD.MM.YYYY")}
          </span>
        </Title>
      </Card.Content>
    </Card>
  )
}

export default ArticlesSectionItem
