import React from "react"
import ReactPlayer from "react-player"

const WorksSectionItem = ({ item }) => {
  const { url } = item

  if (!["YOUTUBE", "FACEBOOK"].includes(item.url_source)) return ""

  return (
    <div className="player-wrapper-landing">
      <ReactPlayer
        className="video-player"
        url={url}
        width="300px"
        height="170px"
      />
    </div>
  )
}

export default WorksSectionItem
